import React from "react";
import dashboard from "../../../../assets/img/powerBI-portada.png";
import logoDtop from "../../../../assets/img/logo_DTOP.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import { SignInButton } from "../../../../components/siginButton/SignInButton";

export const Login = ({ history }) => {
  return (
    <div style={{ height: "100vh" }}>
      <Container className="h-100" fluid>
        <Row className="h-100 align-content-center">
          <Col sm="12" md="6" className="text-center mt-5 order-1 order-sm-1">
            <h1 className="login__title">DTOP Analítica Dashboard</h1>
            <img src={logoDtop} alt="Logo DTOP" width={"35%"} />
            <p className="login__text mb-5 fw-semibold">
              Bienvenidos al Sistema de
              <br />
              Analítica STRAB del DTOP. Por favor, hacer click en el
              <br />
              botón para Iniciar Sesión.
            </p>
            <SignInButton history={history} />
          </Col>
          <Col sm="12" md="6" className="text-center mt-5 order-0 order-sm-2">
            <img
              src={dashboard}
              className="login__logo"
              alt="Dashboard Logo"
              width={"75%"}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
