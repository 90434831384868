import React, { useContext } from "react";

import facturador from "../../../../assets/img/facturador.jpg";
import dashbKPI from "../../../../assets/img/KPI-operativos.png";
import { AuthContext } from "../../../../context/AuthContext";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import { SidebarData } from "../../../../utils/constants/SidebarData";

function Home() {
  const { user } = useContext(AuthContext);
  const isAdmin =
    user.userInfo.account.username.search("@admin.strabpr.com") > 0 || user.userInfo.account.username == "jlondono@softekpr.com";
  const currentBoards = [
    {
      id: "1",
      routePath: "/kpigenerales",
      imgSrc: dashbKPI,
      title: "Indicadores Generales",
    },
    {
      id: "2",
      routePath: "/facturador",
      imgSrc: facturador,
      title: "Ingresos Multas Ley 22",
    },
  ];
  const cardItems = currentBoards.map((item) => {
    const result = SidebarData.filter(
      (el) => el.id === item.id && el.path === item.routePath
    );
    const flag =
      result[0].domains?.includes("*") ||
      result[0].domains?.includes(
        "@" + user.userInfo.account.username.split("@")[1]
      );
    if ((isAdmin && item.id === "2") || flag || user.userInfo.account.username == "jlondono@softekpr.com") {
      return (
        <Col key={item.id} className="mt-3">
          <LinkContainer to={item.routePath} exact>
            <Card className="h-100 text-decoration-none" as="a">
              <Card.Img variant="top" src={item.imgSrc} className="h-100" />
              <Card.Body>
                <Card.Title>
                  {item.title}
                </Card.Title>
              </Card.Body>
            </Card>
          </LinkContainer>
        </Col>
      );
    }
  });
  return (
    <Container className="py-5">
      <Row xs={1} md={2}>
        {cardItems}
      </Row>
    </Container>
  );
}

export default Home;
