import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Badge from "react-bootstrap/Badge";
import * as FaIcons from "react-icons/fa";
import { SidebarData } from "../../utils/constants/SidebarData";
import ListGroup from "react-bootstrap/ListGroup";
import { AuthContext } from "../../context/AuthContext";
import { LinkContainer } from "react-router-bootstrap";

function OffcanvasNav() {
  const { user } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isAdmin =
    user.userInfo.account.username.search("@admin.strabpr.com") > 0 || user.userInfo.account.username == "jlondono@softekpr.com";

  return (
    <>
      <Button
        variant="outline-light"
        onClick={handleShow}
        className="navbar-toggler me-3"
      >
        <FaIcons.FaBars />
      </Button>
      <Offcanvas
        id="main-offcanvas"
        aria-labelledby={`offcanvasNavbarLabel`}
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton id={`offcanvasNavbarLabel`}>
          <Offcanvas.Title>{user.userInfo.account.username}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <ListGroup variant="flush">
            {SidebarData.map((item, index) => {
              if (
                isAdmin ||
                item.domains?.includes("*") ||
                item.domains?.includes(
                  "@" + user.userInfo.account.username.split("@")[1]
                )
              )
                return (
                  <LinkContainer
                    to={item.path}
                    exact
                    key={`link-container-${index}`}
                  >
                    <ListGroup.Item
                      active={false}
                      key={`list-g-${index}`}
                      action
                      as="a"
                      className="d-flex justify-content-between align-items-start"
                      onClick={handleClose}
                    >
                      <Badge bg="dark" pill className="p-2">
                        {item.icon}
                      </Badge>
                      <div className="ms-2 me-auto p-2">
                        <div className="fw-bold">{item.title}</div>
                      </div>
                    </ListGroup.Item>
                  </LinkContainer>
                );
            })}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffcanvasNav;
