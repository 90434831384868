import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

let reactPlugin = new ReactPlugin();
let appInsights = null;
let browserHistory = createBrowserHistory({ basename: "" });
/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        "InstrumentationKey=374c2da9-4d1f-40f0-95c3-0e3e6f26cdc8;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/",
      maxBatchInterval: 0,
      disableFetchTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {
          history: browserHistory,
        },
      },
    },
  });

  appInsights.loadAppInsights();

  return appInsights;
};

export const ai = createTelemetryService();