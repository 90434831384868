import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import * as FaIcons from "react-icons/fa";
import { useLocation } from "react-router-dom";

function NavContent() {
  const location = useLocation();
  const currentRoute = location.pathname;
  if (currentRoute == "/login") {
    return (
      <LinkContainer to="/faq">
        <Nav.Link className="text-end">
          <FaIcons.FaInfoCircle className="mb-1 mx-1" />
          FAQ
        </Nav.Link>
      </LinkContainer>
    );
  }
  return (
    <LinkContainer to="/login">
      <Nav.Link className="text-end">
        <FaIcons.FaRegUserCircle className="mb-1 mx-1" />
        Login
      </Nav.Link>
    </LinkContainer>
  );
}

function PublicNavbar() {
  return (
    <Navbar variant="light" bg="light" expand={true} fixed="top">
      <Container fluid>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavContent></NavContent>
            {/* <LinkContainer to="/faq">
              <Nav.Link className="text-end">
                <FaIcons.FaInfoCircle className="mb-1 mx-1" />
                FAQ
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/login">
              <Nav.Link className="text-end">
                <FaIcons.FaRegUserCircle className="mb-1 mx-1" />
                Login
              </Nav.Link>
            </LinkContainer> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;
