class FetchClient {
  static async fetchData(url, options = {}) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "content-type",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
      },
    };

    // Combina requestOptions con options utilizando el operador de propagación
    const mergedOptions = {
      ...requestOptions,
      ...options,
      headers: {
        ...requestOptions.headers,
        ...options.headers,
      },
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_BASE_URL + url, mergedOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Hubo un error en la solicitud');
      }

      return data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error;
    }
  }
}

export default FetchClient;
