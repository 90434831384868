import React, { useContext, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { AuthContext } from "../../../context/AuthContext";
import "../pages/home/Home.css";
import Spinner from "react-bootstrap/Spinner";
import FetchService from '../../../utils/FetchService';

function Licencias() {
  const { user, dispatch } = useContext(AuthContext);

  const [biToken, setbiToken] = useState("");
  const isAdmin =
    user.userInfo.account.username.search("@admin.strabpr.com") > 0 || user.userInfo.account.username == "jlondono@softekpr.com";

    const requestOptions = {
      headers: {
        "Authorization": `Bearer ${user.userInfo.accessToken}`
      }
    };

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    FetchService.fetchData(
      `?rt=4&ut=${isAdmin ? 99 : 8
      }`,
      requestOptions
    )
      .then((response) => response)
      .then((res) => {
        setbiToken(res?.data);
      })
      .catch((ex) => console.log(ex));
  }, []);

  return (
    <form>
      {biToken ? (
        <PowerBIEmbed
          width="50%"
          height="50%"
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: "c6542996-5e1e-4e12-9a7a-796bd0409e0a",
            embedUrl:
              "https://app.powerbi.com/reportEmbed?reportId=c6542996-5e1e-4e12-9a7a-796bd0409e0a&groupId=e8f0ad8a-895f-4495-991b-56d4c3509784&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
            accessToken: biToken,
            tokenType: models.TokenType.Embed,
            pageView: models.PageSizeType.Cortana,
            settings: {
              navContentPaneEnabled: false,
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"Embed-container"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      ) : (
        <div className="home">
          <Spinner
            variant="primary"
            animation="border"
            role="status"
            style={{ width: "6rem", height: "6rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </form>
  );
}

export default Licencias;
