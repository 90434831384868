import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import NavbarMain from "../../components/navbar/NavbarMain";

import KpiGenerales from "./pages/KpiGenerales";
import KpiOperativos from "./pages/KpiOperativos";
import Facturador from "./pages/Facturador";
import FacturadorGeneral from "./pages/FacturadorGeneral";
import Marbetes from "./pages/Marbetes";
import Licencias from "./pages/Licencias";
import Cots from "./pages/COTS";
import CertificacionTickets from "./pages/CertificacionTickets";
import Home from "./pages/home/Home";
import LicenciasRealId from "./pages/LicenciasRealId";
import Reportes from "./pages/Reportes";
import Multas from "./pages/Multas";
import Faq from "../FAQ";

export const Dashboard = () => {
  return (
    <>
      <NavbarMain />
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/kpigenerales" component={KpiGenerales} />
          <Route path="/kpioperativos" component={KpiOperativos} />
          <Route path="/facturador" component={Facturador} />
          <Route path="/facturadorgeneral" component={FacturadorGeneral} />
          <Route path="/marbetes" component={Marbetes} />
          <Route path="/licencias" component={Licencias} />
          <Route path="/multas-cot" component={Cots} />
          <Route path="/certificacion-boletos-pagos" component={CertificacionTickets} />
          <Route path='/licencias-real-id' component={LicenciasRealId} />
          <Route path='/reportes' component={Reportes} />
          <Route path='/multas' component={Multas} />
          <Route path="/faq" component={Faq} />
          <Redirect to="/" />
        </Switch>
      </Router>
    </>
  );
};
