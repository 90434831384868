import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthContext } from "../context/AuthContext";
import { PrivateRouter } from "./PrivateRouter";
import { PublicRouter } from "./PublicRouter";

import Faq from "../views/FAQ";
import { Auth } from "../views/auth/auth";
import { Dashboard } from "../views/dashboard/Dashboard";
export const AppRouter = () => {
  const { user } = useContext(AuthContext);

  return (
    <Router>
      <Switch>
        <PublicRouter
          exact
          path="/login"
          component={Auth}
          isAuthenticated={user.logged}
        />

        <Route path="/faq" component={Faq} />
        <PrivateRouter
          path="/"
          component={Dashboard}
          isAuthenticated={user.logged}
        />
      </Switch>
    </Router>
  );
};
