import { useContext } from "react";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Figure from "react-bootstrap/Figure";
import { AuthContext } from "../context/AuthContext";
import PublicNavbar from "../components/navbar/PublicNavbar";
import NavbarMain from "../components/navbar/NavbarMain";

function FAQ() {
  const { user } = useContext(AuthContext);
  const Navbar = user.logged ? NavbarMain : PublicNavbar;
  return (
    <>
    <Navbar></Navbar>
      <section>
        <Container className="py-5 text-left">
          <h1 className="fw-light display-1 fw-bold mt-5">FAQs</h1>
          <p className="lead text-muted">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut id
            tortor consectetur, varius metus a, porttitor nunc. Nam tincidunt ac
            elit eget egestas. Nulla dolor massa, tempor a velit eu, dapibus
            suscipit magna. Pellentesque dignissim mollis dignissim.
          </p>
        </Container>
      </section>
      <section className="bg-light" style={{ minHeight: "30vh" }}>
        <Container className="py-5">
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Accordion Item #1</Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Figure className="text-center w-100">
                    <Figure.Image
                      width={171}
                      height={180}
                      alt="171x180"
                      src="https://via.placeholder.com/171x180"
                    />
                    <Figure.Caption>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </Figure.Caption>
                  </Figure>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Accordion Item #2</Accordion.Header>
              <Accordion.Body>
                <Container>
                  <Figure className="text-center w-100">
                    <Figure.Image
                      width={1200}
                      height={150}
                      alt="1200x150"
                      src="https://via.placeholder.com/1200x150"
                    />
                    <Figure.Caption>
                      Nulla vitae elit libero, a pharetra augue mollis interdum.
                    </Figure.Caption>
                  </Figure>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </>
  );
}

export default FAQ;
