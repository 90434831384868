import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import { FaUsersViewfinder } from "react-icons/fa6";
import { TbReport } from "react-icons/tb";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

export const SidebarData = [
  {
    id: "0",
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome size={21} />,
    cName: "nav-text",
    domains: ["*"],
  },
  {
    id: "1",
    title: "Indicadores Generales",
    path: "/kpigenerales",
    icon: <IoIcons.IoIosAnalytics size={21} />,
    cName: "nav-text",
    domains: [
      "@admin.strabpr.com",
      "@disco.strabpr.com",
      "@finanzas.strabpr.com",
    ],
  },
  {
    id: "2",
    title: "Ingresos Multas Ley 22",
    path: "/facturador",
    icon: <IoIcons.IoIosBarcode size={21} />,
    cName: "nav-text",
    domains: [
      "@admin.strabpr.com",
      "@disco.strabpr.com",
      "@finanzas.strabpr.com",
      "@prits.strabpr.com",
      "@elisco.strabpr.com"
    ],
  },
  {
    id: "4",
    title: "Multas COT",
    path: "/multas-cot",
    icon: <IoIcons.IoIosCalculator size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
  {
    id: "5",
    title: "Marbetes y Vehículos",
    path: "/marbetes",
    icon: <FaIcons.FaTrafficLight size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com", "@warroom.strabpr.com"],
  },
  {
    id: "6",
    title: "Indicadores Operativos",
    path: "/kpioperativos",
    icon: <IoIcons.IoMdAnalytics size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
  {
    id: "7",
    title: "Licencias e Identificaciones",
    path: "/licencias",
    icon: <IoIcons.IoIosCard size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
  {
    id: "8",
    title: "Certificación de Boleto Pagos",
    path: "/certificacion-boletos-pagos",
    icon: <FaIcons.FaCertificate size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com", "@hacienda.strabpr.com", "@prits.strabpr.com"],
  },
  {
    id: "9",
    title: "Licencias Real ID",
    path: "/licencias-real-id",
    icon: <FaUsersViewfinder size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
  {
    id: "10",
    title: "Reportes",
    path: "/reportes",
    icon: <TbReport size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
  {
    id: "11",
    title: "Multas",
    path: "/multas",
    icon: <LiaMoneyCheckAltSolid size={21} />,
    cName: "nav-text",
    domains: ["@admin.strabpr.com"],
  },
];
