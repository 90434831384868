import React, { useState, useContext } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import * as FaIcons from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Offcanvas from "../offcanvas/Offcanvas";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../context/AuthContext";
import { types } from "../../utils/constants/types";
import { LinkContainer } from "react-router-bootstrap";
import "./NavbarMain.css"

function NavbarsMain() {
  const { dispatch } = useContext(AuthContext);
  const { instance } = useMsal();

  const logoutHandler = () => {
    instance.logoutRedirect({
      onRedirectNavigate: () => {
        return false;
      },
    });
    dispatch({ type: types.logout });
  };
  return (
    <Navbar variant="dark" expand={false} sticky="top">
      <Container fluid>
        <Offcanvas />
        <LinkContainer to="/" className="d-print-none">
          <Navbar.Brand className="flex-grow-1">DTOP Analitica Dashboard</Navbar.Brand>
        </LinkContainer>
        <Nav className="flex-row">
          {/* <LinkContainer to="/faq" className="mx-2">
            <Nav.Link>
              <FaIcons.FaInfoCircle className="mb-1 mx-1" />
              FAQ
            </Nav.Link>
          </LinkContainer> */}
          <LinkContainer to="/login" className="mx-2 d-print-none">
            <Nav.Link onClick={logoutHandler}>
              <FaIcons.FaDoorOpen className="mb-1 mx-1" />
              Logout
            </Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavbarsMain;
