import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Login} from "./pages/Login/Login";
import PublicNavbar from "../../components/navbar/PublicNavbar";

export const Auth = () => {
  return (
    <>
    {/* <PublicNavbar></PublicNavbar> */}
      <Router>
        <Switch>
            <Route path="/login" component={Login} />
        </Switch>
      </Router>
    </>
  );
};
