import React, { useContext, useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { AuthContext } from "../../../context/AuthContext";
import "../pages/home/Home.css";
import Spinner from "react-bootstrap/Spinner";
import FetchService from '../../../utils/FetchService';

function FacturadorGeneral() {
    const { user, dispatch } = useContext(AuthContext);
    const [biToken,setbiToken]=useState("");
    const isAdmin= user.userInfo.account.username.search('@admin.strabpr.com')>0;
    const requestOptions = {
      headers: {
        "Authorization": `Bearer ${user.userInfo.accessToken}`
      }
    };
   //https://app.powerbi.com/reportEmbed?reportId=55b45a5f-6d3d-4d6a-9cf6-10a66fbb3b76&autoAuth=true&ctid=6530bfb3-2344-4611-b070-e3c758d8beba&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9 
      /* https://pbiebtfunction.azurewebsites.net/api/HttpGetData?rt=1 */   
       useEffect(() =>  {
        // GET request using fetch inside useEffect React hook
        FetchService.fetchData(
          `?rt=7&ut=${isAdmin ? 99 : 10
          }`,
          requestOptions
        )
          .then((response) => response)
          .then((res) => {
            setbiToken(res?.data);
          })
          .catch((ex) => console.log(ex));
    
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <form>
      {biToken ? (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: "d1bea255-32a8-45d8-8bd6-2fe2aafd0c8c",
            embedUrl:
              "https://app.powerbi.com/reportEmbed?reportId=d1bea255-32a8-45d8-8bd6-2fe2aafd0c8c&groupId=e8f0ad8a-895f-4495-991b-56d4c3509784&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
            accessToken: biToken,
            tokenType: models.TokenType.Embed,
            pageView: models.PageSizeType.Cortana,
            settings: {
              navContentPaneEnabled: false,
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
            ])
          }
          cssClassName={"Embed-container"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      ) : (
        <div className="home">
          <Spinner
            variant="primary"
            animation="border"
            role="status"
            style={{ width: "6rem", height: "6rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
    </form>
  );
}

export default FacturadorGeneral;
