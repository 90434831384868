import React, { useContext } from "react";
import "./SignInButton.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../utils/constants/AuthConfig";

import { AuthContext } from "../../context/AuthContext";
import { types } from "../../utils/constants/types";
import { ai } from "../../utils/TelemetryService";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = ({ history }) => {
    
  const { instance } = useMsal();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (instance) => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        ai.trackEvent({
          name: "login",
          properties: (({
            homeAccountId,
            environment,
            tenantId,
            username,
            name,
            localAccountId,
          }) => ({
            homeAccountId,
            environment,
            tenantId,
            username,
            name,
            localAccountId,
          }))(response.account),
        });
        dispatch({
          type: types.login,
          payload: response,
        });

        history.replace("/");
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <button
      type="button"
      className="signin__btn mx-auto"
      onClick={() => handleLogin(instance)}
    >
      Iniciar Sesión
    </button>
  );
};
