import React, { useEffect, useReducer } from "react";
import './App.css';

import { AppRouter } from "./routes/AppRouter";

import { MsalProvider } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { AuthContext } from './context/AuthContext';
import { AuthReducer } from './context/AuthReducer';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { types } from "./utils/constants/types";
import { loginRequest } from "./utils/constants/AuthConfig";

const init = () => {
  return JSON.parse(sessionStorage.getItem('user')) || { logged: false };
}

function updateAccessToken(instance, dispatch) {
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    instance.acquireTokenSilent(request).then(response => {
      dispatch({
        type: types.login,
        payload: response,
      });
    }).catch(error => {
      // acquireTokenSilent can fail for a number of reasons, fallback to interaction
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenPopup(request).then(response => {
          dispatch({
            type: types.login,
            payload: response,
          });
        });
      }
    });
  }
}

function App({ pca }) {
  const [user, dispatch] = useReducer(AuthReducer, {}, init);
  useEffect(() => {
    sessionStorage.setItem('user', JSON.stringify(user));

    pca.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        pca.setActiveAccount(account);
      }
      if (event.eventType === EventType.HANDLE_REDIRECT_START && user != null && user.logged) {
        updateAccessToken(pca, dispatch);
      }
      if(event.eventType === EventType.ACQUIRE_TOKEN_START && event.payload.account){
        const account = event.payload.account;
        pca.setActiveAccount(account);
      }
    });
  }, [user])

  return (
    <MsalProvider instance={pca}>
      <AuthContext.Provider value={{ user, dispatch }}>
        <AppRouter />
      </AuthContext.Provider>
    </MsalProvider>
  );
}

export default App;
