import { types } from '../utils/constants/types';

export const AuthReducer = (state = {}, action ) => {

    switch ( action.type ) {
        case types.login:
            return {
                userInfo: { ...action.payload },
                logged: true
            }

        case types.logout:
            return {
                logged: false
            }
    
        default:
            return state;
    }

}